.fs-8 {
  font-size: 8px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.fs-34 {
  font-size: 34px !important;
}
.fs-36 {
  font-size: 36px !important;
}
