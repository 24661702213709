.afterAnimation {
  opacity: 0;

  animation: body 1s ease 7.5s 1 normal forwards;
}

@keyframes body {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  justify-content: center !important;
  background-color: #e61a3f;
}

.bg-info {
  background-color: #e61a3f;
}

.joinForm,
.createForm {
  position: relative;
}

.fill-form1,
.fill-form2 {
  position: absolute;
  display: none;
  background-color: #d82a2a;
  color: #fff;
  top: 50px;
  left: 40px;
  z-index: 100;
  padding: 5px 5px;
  margin-bottom: 10px;
  /* opacity: 0; */
  transition: 0.2s;
}

span.fill-form1::after {
  position: absolute;
  content: "";
  top: -7px;
  left: 20px;
  width: 15px;
  height: 15px;
  background: #d82a2a;
  z-index: 1;
  transform: rotate(45deg);
}

span.fill-form2::after {
  position: absolute;
  content: "";
  top: -7px;
  left: 20px;
  width: 15px;
  height: 15px;
  background: #d82a2a;
  z-index: 1;
  transform: rotate(45deg);
}

.fill-form1.active,
.fill-form2.active {
  display: block;
  /* opacity: 1; */
}

.btnJoin {
  cursor: pointer;
  background-color: #33ab53;
}

.btnJoin[disabled] {
  cursor: no-drop;
  background-color: #abf4be;
}

.btnCreate[disabled] {
  cursor: no-drop;
  background-color: #99edf5;
}

.joinScreen {
  position: relative;
}

.userExist,
.userJoin {
  display: none;
  position: relative;
  width: fit-content;
  padding: 10px 12px;
  padding-top: 20px;
  background: #ffe98a;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  z-index: -1;
  transition: 0.3;
}

.userExist.active {
  opacity: 1;
  z-index: 1;
  display: flex;
}

.userJoin.active {
  opacity: 1;
  z-index: 1;
}

.userExist p,
.userJoin p {
  margin: 0;
}

.userExist .fa-xmark,
.userJoin .fa-xmark {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 20px;
  cursor: pointer;
}
