.bgcolor-white {
  background-color: #fff;
  text-align: center;
  padding-top: 28px;
  height: 90vh;
}

/* .sc-bottom {
  padding-bottom: 180px;
  margin-bottom: 190px;
} */

/* @media (max-width > 560px) {
  .sc-bottom {
    padding-bottom: 80px;
  }
}

@media (min-width <= 560px) {
  .sc-bottom {
    padding-bottom: 0px;
  }
} */
