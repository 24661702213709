html {
  scroll-behavior: smooth;
}

.active-scroll-spy h5 b {
  border: 10px solid #bda07f;
  background-color: #bda07f;
  color: #fff;
}

/* .active-scroll-spy b {
  font-size: 18px !important;
  font-family: "amithem" !important;
} */

/* .cartAccordion button{
  padding-right: 0; 
  padding-left: 0;
} */
/* accordion Custom style */
.accordion-button::after {
  background-image: url("");
  display: none;
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e"); */
  /* transform: scale(0.7) !important; */
}

.swiper-container {
  position: relative;
  overflow: visible;  /* Set overflow to visible */
}
.swiper-button-next, .swiper-button-prev {
  color: black !important; /* Change the color here */
  width: 25px !important; /* Change the width here */
  height: 25px !important; /* Change the height here */
  font-weight: bold; /* Change the font-weight here */

}
/*.swiper-slide-active {*/
/*  opacity: 1;*/
/*}*/
/*.swiper-slide-next,*/
/*.swiper-slide-prev {*/
/*  opacity: 0.267894;*/
/*}*/

/* Adjust size of arrow inside the button */
.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 25px !important; /* Change the font-size here */
  font-weight: bold; /* Change the font-weight here */

}.swiper-slide-shadow-right, .swiper-slide-shadow-left {
   background-size: 100% 50% !important;
 }

.swiper-slide-shadow-right{
  background-image: linear-gradient(270deg, rgba(70,70,79,0.5) 0%, rgba(3,3,3,0.07) 10%, rgba(8,86,108,0) 89%) !important;
}

.swiper-slide-shadow-left{
  background-image: linear-gradient(90deg, rgba(70,70,79,0.5) 0%, rgba(3,3,3,0.07) 10%, rgba(8,86,108,0) 89%) !important;
}

.swiper-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%);
  pointer-events: none;
}
.swiper-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;  /* Adjust this to control the width of the fade */
  height: 100%;
  background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(128,128,128,1));  /* Replace with the desired grey color */
  pointer-events: none;
}
.accordion-button {
  justify-content: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("");
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e"); */
}
.banner-image {
  padding: 5px;
  height: 25px;
  width: 25px;
  position: absolute;
  background: red;
  top: 0;
  right: 15px;
  border-radius: 20px;
  cursor: pointer !important;
}
@media (max-width: 425px) {
  .banner-image {
    width: 20px !important;
    height: 20px !important;
    right: 5px !important;
    top: 0 !important;
  }
}

/* Font Family */
@font-face {
  font-family: "Source Sans Pro-Black";
  src: url("../../assets/font/SourceSansPro-Black.ttf");
}
@font-face {
  font-family: "Source Sans Pro-BlackItalic";
  src: url("../../assets/font/SourceSansPro-BlackItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro-Bold";
  src: url("../../assets/font/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: "Source Sans Pro-BoldItalic";
  src: url("../../assets/font/SourceSansPro-BoldItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro-ExtraLight";
  src: url("../../assets/font/SourceSansPro-ExtraLight.ttf");
}
@font-face {
  font-family: "Source Sans Pro-ExtraLightItalic";
  src: url("../../assets/font/SourceSansPro-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro-Italic";
  src: url("../../assets/font/SourceSansPro-Italic.ttf");
}
@font-face {
  font-family: "Source Sans Pro-Light";
  src: url("../../assets/font/SourceSansPro-Light.ttf");
}
@font-face {
  font-family: "Source Sans Pro-LightItalic";
  src: url("../../assets/font/SourceSansPro-LightItalic.ttf");
}
@font-face {
  font-family: "Source Sans Pro-Regular";
  src: url("../../assets/font/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: "Source Sans Pro-SemiBold";
  src: url("../../assets/font/SourceSansPro-Semibold.ttf");
}
@font-face {
  font-family: "Source Sans Pro-SemiBoldItalic";
  src: url("../../assets/font/SourceSansPro-SemiboldItalic.ttf");
}

.flicking-panel {
  position: absolute;
  top: 0;
}

.dish-wrapper {
  display: inline-block; /* or inline-flex, depending on your needs */
}

.newdish-wrapper {
  display: inline-flex; /* Changing this to inline-flex might give you more control over the layout */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.newcarousel-item {
  padding: 0; /* Removing padding might reduce space between items */
}

/*.mySwiper .swiper-slide {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  display: flex; !* Flex could help with aligning items as per your needs *!*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/