.paymentFail h1 {
  font-size: 50px;
  font-weight: 600;
  color: #e6213c;
  line-height: 43px;
}

.paymentFail button {
  border-radius: 30px;
  width: 150px;
  background-color: rgb(23, 181, 194);
  height: 40px;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  box-shadow: 5px 5px 10px -5px #000;
}
