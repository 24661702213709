.close-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.search {
  border-radius: 20px;
  background-color: #e0e0e0;
}

input[type="search"]:focus {
  border-color: none;
  box-shadow: none;
  background-color: #e0e0e0;
  outline: 0 none;
}

.bg-color-input {
  background-color: #e0e0e0;
}

.sort-img {
  width: 30px;
  height: 30px;
}

.alergance {
  background-color: #e0e0e0;
  border-radius: 10px;
}

.alerg-img {
  width: 45px;
  height: 45px;
}

.searchactive {
  border: 1px solid grey;
  padding: 4px;
  border-radius: 5px;
  width: 50px;
  height: 50px;
}

.apply-btn {
  border-radius: 20px;
}
