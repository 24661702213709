@font-face {
  font-family: "ER Kurier Mac";
  src: url("../../assets/font/ER-KurierMac.woff");
}

.main_body_div {
  font-family: "ER Kurier Mac" !important;
  letter-spacing: 0.2px;
  background-color: white;
}

.print-btn {
  right: 18px;
  top: 60px;
}

.dash-border {
  border-top: 2px dashed #959393;
  border-bottom: 2px dashed #959393;
  padding-top: 1px;
}

.single-border {
  border-top: 2px dashed #959393;
}

.font-line-height {
  font-size: 18px;
  color: #494949;
  word-spacing: 10px;
}

.lineBreak {
  word-break: break-all !important;
}
